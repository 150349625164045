<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Metode</h2>
      <r-third-level-menu></r-third-level-menu>
    </template>
    <template #action-bar>
      <div class="level">
        <div class="level-left">
          <b-button tag="router-link" to="/master-data/limit/metode/create">
            Create
          </b-button>
        </div>
        <div class="level-right">
          <b-input icon-right="magnify" rounded>Search</b-input>
          <b-button icon-left="filter-variant" class="is-command">
            Filter
          </b-button>
        </div>
      </div>
    </template>
    <template #page-content>
      <r-card>
        <b-table :data="data" :columns="columns"></b-table>
      </r-card>
      <r-card>
        <b-table :data="data" :columns="columns"></b-table>
      </r-card>
      <router-view />
    </template>
  </r-page>
</template>
<script>
export default {
  data() {
    return {
      data: [
        {
          id: 1,
          first_name: 'Jesse',
          last_name: 'Simmons',
          date: '2016-10-15 13:43:27',
          gender: 'Male',
        },
        {
          id: 2,
          first_name: 'John',
          last_name: 'Jacobs',
          date: '2016-12-15 06:00:53',
          gender: 'Male',
        },
        {
          id: 3,
          first_name: 'Tina',
          last_name: 'Gilbert',
          date: '2016-04-26 06:26:28',
          gender: 'Female',
        },
        {
          id: 4,
          first_name: 'Clarence',
          last_name: 'Flores',
          date: '2016-04-10 10:28:46',
          gender: 'Male',
        },
        {
          id: 5,
          first_name: 'Anne',
          last_name: 'Lee',
          date: '2016-12-06 14:38:38',
          gender: 'Female',
        },
      ],
      columns: [
        {
          field: 'id',
          label: 'ID',
          width: '40',
          numeric: true,
        },
        {
          field: 'first_name',
          label: 'First Name',
        },
        {
          field: 'last_name',
          label: 'Last Name',
        },
        {
          field: 'date',
          label: 'Date',
          centered: true,
        },
        {
          field: 'gender',
          label: 'Gender',
        },
      ],
    }
  },
}
</script>
